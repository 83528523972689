<template>
  <div class="flex-col">
    <h1>Oops!</h1>
    <h3>The {{ resource }} you're looking for is not here.</h3>
    <router-link class="mx-auto mt-4" :to="{ name: 'Home' }">
      Back to the home page
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    resource: {
      type: String,
      required: false,
      default: 'resource'
    }
  }
}
</script>

<style scoped></style>
