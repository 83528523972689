import _ from 'lodash'

import Vue from 'vue'
import Vuex from 'vuex'

import rcg from '../data/rcg.js'

import rcgCalcQx from '../data/rcgCalcQx.js'

import rcgCalcRules from '../data/rcgCalcRules.js'

import rcgCalcProposals from '../data/rcgCalcProposals.js'
const rcgCalcProposalsObj = JSON.parse(atob(rcgCalcProposals))

import ruleTraverser from './RulesProcessor'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    rcg: JSON.parse(atob(rcg)), // RCGFlow#decodeAndLoad
    rcgCalcQx: JSON.parse(atob(rcgCalcQx)),
    rcgCalcRules: JSON.parse(atob(rcgCalcRules)),
    proposalData: {}
  },
  mutations: {
    WRITE_PROPOSAL(state, payload) {
      state.proposalData = payload
    }
  },
  actions: {
    // RCGCalcFlow.md#r0
    constructProposal({ commit, state }, answers) {
      // RCGCalcFlow.md#r1
      const selectedProposalItems = ruleTraverser(state.rcgCalcRules, answers)
      programsSelected = []
      // RCGCalcFlow.md#r2
      const proposal = proposalTraverser(
        _.cloneDeep(rcgCalcProposalsObj),
        selectedProposalItems
      )
      if (!_.isEmpty(programsSelected)) {
        const costsSection = _.find(_.cloneDeep(rcgCalcProposalsObj).related, [
          'properties.PROPSECTIONTYPE',
          'COSTS'
        ])
        costsSection.related = programsSelected
        proposal.related.push(costsSection)
      }
      commit('WRITE_PROPOSAL', proposal)
    }
  }
})

function getPropItems(relatedNodes) {
  let val = {}
  for (let relatedNode of relatedNodes) {
    if (relatedNode.properties['PROPITEMID']) {
      val[relatedNode.properties['PROPITEMID']] = relatedNode
    } else {
      const nestedPropItems = getPropItems(relatedNode.related)
      if (!_.isEmpty(nestedPropItems)) {
        val = { ...val, ...nestedPropItems }
      }
    }
  }
  return val
}

let programsSelected
function proposalTraverser(proposalNode, selections) {
  if (Object.keys(selections).includes(proposalNode.properties['ID'])) {
    proposalNode.calcAnswer = selections[proposalNode.properties['ID']]
    if (proposalNode.properties['PERHOUR']) {
      proposalNode.cost =
        parseFloat(proposalNode.properties['PERHOUR']) *
        selections[proposalNode.properties['ID']].properties['HOURS']
    }
    if (proposalNode.properties['PERUNIT']) {
      proposalNode.cost =
        parseFloat(proposalNode.properties['PERUNIT']) *
        selections[proposalNode.properties['ID']].properties['UNITS']
    }
    if (proposalNode.properties['PROPSECTIONTYPE'] === 'PROGITEM') {
      // If this is PROGRAM, then fetch all included program elements, ie the ones that have got PROPITEMID
      const programPropItems = getPropItems(
        selections[proposalNode.properties['ID']].related
      )

      // And build it's render out of Price Table section
      const priceProposalSection = _.find(rcgCalcProposalsObj.related, [
        'properties.PROPSECTIONTYPE',
        'PRICES'
      ])
      const programProposal = proposalTraverser(
        _.cloneDeep(_.cloneDeep(priceProposalSection)),
        programPropItems
      )
      proposalNode.cost = programProposal.cost
      proposalNode.related = programProposal.related
    }
    return proposalNode
  }
  let relatedSelected = []
  let nodeCost = 0.0
  let selected
  for (let relatedNode of proposalNode.related) {
    selected = proposalTraverser(relatedNode, selections)
    if (selected !== null) {
      relatedSelected.push(selected)
      if (selected.cost) {
        nodeCost += selected.cost
      }
      if (
        selected.properties &&
        selected.properties['PROPSECTIONTYPE'] === 'PROGRAM'
      ) {
        programsSelected = selected.related
      }
    }
  }
  if (relatedSelected.length === 0) {
    return null
  }
  proposalNode.related = relatedSelected
  proposalNode.cost = nodeCost
  return proposalNode
}

export default store
