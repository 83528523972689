<template>
  <section>
    <h1 class="mx-auto mb-3" :id="productsPage.properties['ID']">
      {{ productsPage.headline }}
    </h1>
    <div>{{ productsPage.body }}</div>
    <section
      v-for="product in productsPage.related"
      :key="product.key"
      class="my-3 pl-2"
    >
      <h3 class="text-left" :id="product.properties['ID']">
        {{ product.headline }}
      </h3>
      <div>{{ product.body }}</div>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  computed: {
    ...mapState(['rcg']),
    productsPage() {
      return _.find(this.rcg, ['properties.NAVLINKNAME', 'Products'])
    }
  }
}
</script>

<style scoped></style>
