<template>
  <section>
    <h1 class="mx-auto mb-3" :id="aboutPage.properties.ID">
      {{ aboutPage.headline }}
    </h1>
    <div class="w-full">{{ aboutPage.body }}</div>
    <section
      v-for="aboutSection in aboutPage.related"
      :key="aboutSection.key"
      class="my-3 w-full"
    >
      <h3 class="text-left" :id="aboutSection.properties.ID">
        {{ aboutSection.headline }}
      </h3>
      <div class="w-full">{{ aboutSection.body }}</div>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  computed: {
    ...mapState(['rcg']),
    aboutPage() {
      return _.find(this.rcg, ['properties.NAVLINKNAME', 'About Us'])
    }
  }
}
</script>

<style scoped></style>
