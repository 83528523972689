<template>
  <section class="!w-full flex-col">
    <div v-show="!isProposalReady">
      <h1 class="mx-auto">{{ rcgCalcQx.headline }}</h1>
      <div class="mx-auto w-3/4 text-sm">{{ rcgCalcQx.body }}</div>
    </div>
    <div class="border mx-auto w-1/2 p-4 mt-4">
      <!-- // RCGCalcFlow.md#0 -->
      <CalcProposal :proposalVars="proposalVars" v-if="isProposalReady" />
      <div v-else-if="branchedSection" class="w-full grid grid-cols-1">
        <section v-if="Object.keys(errors).length > 0">
          <div
            v-for="error in Object.keys(errors)"
            :key="error"
            class="bg-red-800 text-center text-teal-100"
          >
            <b>{{ error }}</b>
          </div>
        </section>

        <div v-if="isQuestion(branchedSection)">
          <section v-if="branchedSection.properties['QTYPE_ALL'] === 'Slide'">
            <h1>{{ branchedSection.headline }}</h1>
            <span class="font-bold">{{ branchedSection.body }}</span>
            <CalcQuestion
              v-for="sliderQuestion in branchedSection.related"
              :key="sliderQuestion.key"
              :question="sliderQuestion"
              ref="calcQuestion"
              v-on:answer:changed="
                handleSliderAnswerChange(branchedSection, $event)
              "
              class="my-2"
            />
          </section>
          <CalcQuestion
            v-else
            :question="branchedSection"
            ref="calcQuestion"
            v-on:answer:changed="handleAnswerChange"
          />
        </div>
        <div v-else>
          <h1>{{ branchedSection.headline }}</h1>
          <span class="font-bold">{{ branchedSection.body }}</span>
          <span
            v-for="branchedQuestion in branchedSection.related"
            :key="branchedQuestion.key"
            class="my-4"
          >
            <template
              v-if="branchedQuestion.properties['QTYPE_ALL'] === 'Slide'"
            >
              <h1>{{ branchedQuestion.headline }}</h1>
              <span class="font-bold">{{ branchedQuestion.body }}</span>
              <CalcQuestion
                v-for="sliderQuestion in branchedQuestion.related"
                :key="sliderQuestion.key"
                :question="sliderQuestion"
                ref="calcQuestion"
                v-on:answer:changed="
                  handleSliderAnswerChange(branchedQuestion, $event)
                "
                class="my-2"
              />
            </template>
            <CalcQuestion
              v-else
              :question="branchedQuestion"
              ref="calcQuestion"
              v-on:answer:changed="handleAnswerChange"
            />
          </span>
        </div>
        <button
          class="border border-gray-700 bg-gray-300 w-56 justify-self-end my-1"
          @click="submitUsGovHardTyranny"
        >
          usGovHardTyranny
        </button>
        <button
          class="border border-gray-700 bg-gray-300 w-56 justify-self-end my-1"
          @click="randomAnswer"
        >
          Randomly Answer
        </button>
        <button
          class="border border-gray-700 bg-gray-300 w-56 justify-self-end my-1"
          @click="uploadAnswersJSON"
        >
          upload Answers File
        </button>

        <button
          class="border border-gray-700 bg-gray-300 w-20 justify-self-end my-1"
          @click="handleBranchedSection"
        >
          Submit
        </button>
      </div>
    </div>
    <span class="m-auto">
      <button
        class="border border-gray-700 bg-gray-300 w-48 mx-2 my-1"
        @click="clear"
      >
        Clear
      </button>
      <button
        class="border border-gray-700 bg-gray-300 w-48 m-auto my-1"
        @click="downloadAnswersJSON"
      >
        download Answers File
      </button>
    </span>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

import CalcQuestion from '@/components/CalcQuestion'
import CalcProposal from '@/components/CalcProposal'

import { usGovHardTyranny } from '@/common/answers.js'

const filterDeep = (entries, infiltrator) => {
  const filtered = _.filter(entries, infiltrator)
  for (let filteredEntry of filtered) {
    if (filteredEntry.related.length > 0) {
      filteredEntry.related = filterDeep(filteredEntry.related, infiltrator)
    }
  }
  return filtered
}

const defaultInitData = {
  answers: {}, // RCGCalcFlow.md#q3
  branched: [],
  branches: {},
  branchedIdx: 0,
  errors: {},
  proposalVars: {}
}

function getInitData() {
  return JSON.parse(JSON.stringify(defaultInitData))
}

export default {
  name: 'CalculatorPage',
  data: function () {
    return getInitData()
  },
  created: function () {
    // RCGCalcFlow.md#q1
    this.branched = JSON.parse(JSON.stringify(this.rcgCalcQx.related))
  },
  components: {
    CalcQuestion,
    CalcProposal
  },
  computed: {
    ...mapState(['rcgCalcQx', 'proposalData']),
    branchedSection() {
      // RCGCalcFlow.md#q2
      // this.branched contains all filtered data
      // this.branchedIdx points to current user page within questionnaire
      return this.branched[this.branchedIdx]
    },
    isProposalReady() {
      return !_.isEmpty(this.proposalData)
    }
  },
  methods: {
    // RCGCalcFlow.md#q3answer
    handleAnswerChange({ question, answer }) {
      this.answers[question.properties['ID']] = answer
      if (question.properties['BRANCHQTYPE_ALL']) {
        const selectedBranch = _.find(question.related, [
          'properties.ID',
          answer[0]
        ])
        this.branches[question.properties['BRANCHQTYPE_ALL']] =
          selectedBranch.properties['ANSWER_ALL']
      }
      if (question.properties['PROPOSALVAR_ALL']) {
        if (['Email', 'Text'].includes(question.properties['QTYPE_ALL'])) {
          this.proposalVars[question.properties['PROPOSALVAR_ALL']] = answer[0]
        } else {
          const selectedAnswer = _.find(question.related, [
            'properties.ID',
            answer[0]
          ])
          this.proposalVars[question.properties['PROPOSALVAR_ALL']] =
            selectedAnswer.properties['ANSWER_ALL']
        }
      }
    },
    // RCGCalcFlow.md#q3slider
    handleSliderAnswerChange(sliderWrapper, { question, answer }) {
      if (!this.answers[sliderWrapper.properties['ID']]) {
        this.answers[sliderWrapper.properties['ID']] = {}
      }
      this.answers[sliderWrapper.properties['ID']][question.properties['ID']] =
        answer
    },
    validateAnswers() {
      this.errors = {}
      // validate if user answered all questions
      const validator = (question) => {
        // In case no answers provided, skip checking
        if (
          question.related.length === 0 &&
          !['Email', 'Text'].includes(question.properties['QTYPE_ALL'])
        ) {
          return
        }
        if (!Object.keys(this.answers).includes(question.properties['ID'])) {
          this.errors['Please answer every question.'] = true
        } else if (question.properties['QTYPE_ALL'] === 'Slide') {
          for (let sliderQuestion of question.related) {
            if (
              !Object.keys(this.answers[question.properties['ID']]).includes(
                sliderQuestion.properties['ID']
              )
            ) {
              this.errors['Please answer every question.'] = true
            }
          }
        } else if (question.properties['QTYPE_ALL'] === 'Email') {
          const emailReg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
          if (!emailReg.test(this.answers[question.properties['ID']])) {
            this.errors['Please enter valid email address.'] = true
          }
        }
      }
      if (this.isQuestion(this.branchedSection)) {
        validator(this.branchedSection)
      } else {
        for (let question of this.branchedSection.related) {
          validator(question)
        }
      }
    },
    async downloadAnswersJSON() {
      const filename = 'generatedAnswers.json'
      const answersJSON = JSON.stringify(
        {
          answers: this.answers,
          proposalVars: this.proposalVars,
          branches: this.branches,
          branchedIdx: this.branchedIdx,
          branched: this.branched
        },
        undefined,
        2
      )
      const blob = new Blob([answersJSON], {
        type: 'text/json'
      })
      const link = document.createElement('a')

      link.download = filename
      link.href = window.URL.createObjectURL(blob)
      link.dataset.downloadurl = ['text/json', link.download, link.href].join(
        ':'
      )

      const evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })

      link.dispatchEvent(evt)
      link.remove()
    },
    async submitUsGovHardTyranny() {
      this.answers = usGovHardTyranny.answers
      this.proposalVars = usGovHardTyranny.proposalVars
      this.branches = usGovHardTyranny.branches
      this.branchedIdx = usGovHardTyranny.branchedIdx
      this.branched = usGovHardTyranny.branched
      await this.handleBranchedSection()
    },
    async randomAnswer() {
      const calcQuestionRefs = Array.isArray(this.$refs.calcQuestion)
        ? this.$refs.calcQuestion
        : [this.$refs.calcQuestion]
      for (let calcQuestionComponent of calcQuestionRefs) {
        calcQuestionComponent.randomiseAnswer()
      }
    },
    async uploadAnswersJSON() {
      const input = document.createElement('input')
      input.type = 'file'

      input.onchange = (e) => {
        // getting a hold of the file reference
        const file = e.target.files[0]

        // setting up the reader
        let reader = new FileReader()
        reader.readAsText(file, 'UTF-8')

        // here we tell the reader what to do when it's done reading...
        reader.onload = async (readerEvent) => {
          const content = readerEvent.target.result // this is the content!
          const generatedAnswers = JSON.parse(content)
          this.answers = generatedAnswers.answers
          this.proposalVars = generatedAnswers.proposalVars
          this.branches = generatedAnswers.branches
          this.branchedIdx = generatedAnswers.branchedIdx
          this.branched = generatedAnswers.branched
          await this.handleBranchedSection()
          input.remove()
        }
      }

      input.click()
    },
    // RCGCalcFlow.md#q4
    async handleBranchedSection() {
      this.validateAnswers()
      if (Object.keys(this.errors).length === 0) {
        // filter questionnaire to currently selected branches before moving on
        this.branched = filterDeep(
          JSON.parse(JSON.stringify(this.rcgCalcQx.related)),
          (qx) => {
            return (
              qx.properties &&
              (!qx.properties['BRANCH_ALL'] ||
                qx.properties['BRANCH_ALL']
                  .split(' ')
                  .every((reqBranch) =>
                    Object.values(this.branches).includes(reqBranch)
                  ))
            )
          }
        )
        if (this.branchedIdx + 1 >= this.branched.length) {
          // RCGCalcFlow.md#q4rules
          await this.$store.dispatch(
            'constructProposal',
            JSON.parse(JSON.stringify(this.answers))
          )
        } else {
          this.branchedIdx += 1
        }
      }
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    isQuestion(section) {
      return (
        section.properties['QTYPE_ALL'] &&
        section.properties['QTYPE_ALL'].split(' ').length === 1
      )
    },
    clear() {
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/home';
</style>
