<template>
  <section>
    <h3 class="text-left">{{ section.headline }}</h3>
    <div v-if="section.body" class="text-sm">
      {{ section.body }}
    </div>
    <section
      v-for="proposalSection in section.related"
      :key="proposalSection.properties['ID']"
    >
      <h4 class="text-right">{{ proposalSection.headline }}</h4>
      <section
        v-for="proposalProgram in proposalSection.related"
        :key="proposalProgram.properties['ID']"
      >
        <h4 class="text-right bg-red-300 mb-0">
          {{ proposalProgram.headline }}
        </h4>
        <section
          v-for="proposalProgramElmntType in proposalProgram.related"
          :key="proposalProgramElmntType.properties['ID']"
          class="grid grid-rows-[fit-content(40%)_fit-content(40%)]"
        >
          <section class="text-left pl-2 border border-red-800 bg-yellow-500">
            <span class="inline-block">{{
              proposalProgramElmntType.headline
            }}</span>
            <span
              v-if="proposalProgramElmntType.body"
              class="text-sm text-right inline-block"
            >
              {{ proposalProgramElmntType.body }}
            </span>
            <div class="text-right inline-block float-right pr-1">
              <span>{{ renderPrice(proposalProgramElmntType.cost) }}</span>
            </div>
          </section>
          <section class="border border-gray-700">
            <div
              v-for="proposalProgramElmnt in proposalProgramElmntType.related"
              :key="proposalProgramElmnt.properties['ID']"
              class="grid grid-rows-[fit-content(40%)_fit-content(40%)] bg-cyan-400"
            >
              <div class="grid grid-cols-2 border border-black">
                <div class="text-right pr-3 border-r-2 border-r-black">
                  {{ proposalProgramElmnt.headline }}
                  <div
                    v-if="proposalProgramElmnt.body"
                    class="text-sm text-right"
                  >
                    {{ proposalProgramElmnt.body }}
                  </div>
                </div>
                <div class="text-right pr-1">
                  <span>{{ renderPrice(proposalProgramElmnt.cost) }}</span>
                </div>
              </div>
              <div>
                <section
                  v-for="proposalProgramElmntSpec in proposalProgramElmnt.related"
                  :key="proposalProgramElmntSpec.properties['ID']"
                  class="bg-gray-200 grid grid-cols-2"
                >
                  <div class="border-r border-black pl-2">
                    {{ proposalProgramElmntSpec.headline }}
                    <div
                      v-if="proposalProgramElmntSpec.body"
                      class="text-sm text-right"
                    >
                      {{ proposalProgramElmntSpec.body }}
                    </div>
                  </div>
                  <div class="text-right pr-1">
                    <span>{{
                      renderPrice(proposalProgramElmntSpec.cost)
                    }}</span>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
import { renderPrice } from '@/common/helpers.js'
export default {
  name: 'ProposalCosts',
  props: {
    section: {
      type: Object,
      required: true
    },
    proposalVars: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    renderPrice(cost) {
      return renderPrice(cost)
    }
  }
}
</script>

<style scoped></style>
