<template>
  <div>
    <section
      v-for="proposalSection in proposalData.related"
      :key="proposalSection.properties['ID']"
      class="block my-8"
    >
      <ProposalCover
        v-if="proposalSection.properties['PROPSECTIONTYPE'] === 'COVER'"
        :section="proposalSection"
        :proposalVars="proposalVars"
      />
      <ProposalProposals
        v-else-if="proposalSection.properties['PROPSECTIONTYPE'] === 'PROPOSAL'"
        :section="proposalSection"
        :proposalVars="proposalVars"
      />
      <ProposalCosts
        v-else-if="proposalSection.properties['PROPSECTIONTYPE'] === 'COSTS'"
        :section="proposalSection"
        :proposalVars="proposalVars"
      />
    </section>
    <section class="text-center border-4 border-emerald-600">
      <div class="inline-block">TOTAL EXPECTED SPEND:</div>
      <div class="inline-block font-bold pl-2">
        {{ renderPrice(proposalData.cost) }}
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { renderPrice } from '@/common/helpers.js'

import ProposalCover from '@/components/ProposalCover'
import ProposalProposals from '@/components/ProposalProposals'
import ProposalCosts from '@/components/ProposalCosts'

export default {
  name: 'CalcProposal',
  props: {
    proposalVars: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    ...mapState(['proposalData'])
  },
  components: {
    ProposalCover,
    ProposalProposals,
    ProposalCosts
  },
  methods: {
    renderPrice(cost) {
      return renderPrice(cost)
    }
  }
}
</script>

<style scoped></style>
