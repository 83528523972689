<template>
  <section>
    <h1 v-html="renderTemplated(section.headline)"></h1>
    <div
      v-if="section.body"
      v-html="renderTemplated(section.body)"
      class="text-sm"
    ></div>
    <section
      v-for="proposalSection in section.related"
      :key="proposalSection.properties['ID']"
    >
      <h3
        v-html="renderTemplated(proposalSection.headline)"
        class="text-left"
      ></h3>
      <div
        v-if="proposalSection.body"
        v-html="renderTemplated(proposalSection.body)"
        class="text-sm"
      ></div>
      <section
        v-for="proposalSectionElmnt in proposalSection.related"
        :key="proposalSectionElmnt.properties['ID']"
      >
        <h4
          v-html="renderTemplated(proposalSectionElmnt.headline)"
          class="text-right"
        ></h4>
        <div
          v-if="proposalSectionElmnt.body"
          v-html="renderTemplated(proposalSectionElmnt.body)"
          class="text-sm text-right"
        ></div>
        <section
          v-for="proposalSectionProgram in proposalSectionElmnt.related"
          :key="proposalSectionProgram.properties['ID']"
        >
          <h5
            class="text-right"
            v-html="renderTemplated(proposalSectionProgram.headline)"
          ></h5>
          <div
            v-if="proposalSectionProgram.body"
            class="text-sm text-right"
            v-html="renderTemplated(proposalSectionProgram.body)"
          ></div>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
import { renderTemplated } from '@/common/helpers'

export default {
  name: 'ProposalProposals',
  props: {
    section: {
      type: Object,
      required: true
    },
    proposalVars: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    renderTemplated(textTemplate) {
      return renderTemplated(textTemplate, this.proposalVars)
    }
  }
}
</script>

<style scoped></style>
