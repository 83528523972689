const Mustache = require('mustache')

function renderPrice(cost) {
  cost = cost ? cost : 0.0
  return cost.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })
}

function renderTemplated(textTemplate, vars) {
  return Mustache.render(textTemplate, vars)
}

export { renderPrice, renderTemplated }
