import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/HomePage.vue'
import Calculator from '../views/CalculatorPage.vue'
import Products from '../views/ProductsPage.vue'
import Services from '../views/ServicesPage.vue'
import Resources from '../views/ResourcesPage.vue'
import Partners from '../views/PartnersPage.vue'
import CareersPage from '../views/CareersPage.vue'
import AboutUs from '../views/AboutUs.vue'
import ContactUs from '../views/ContactUs.vue'
import NotFound from '../views/NotFoundPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/calculator',
    name: 'calculator',
    component: Calculator
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Resources
  },
  {
    path: '/partners',
    name: 'Partners',
    component: Partners
  },
  {
    path: '/careers',
    name: 'Careers',
    component: CareersPage
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: AboutUs
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: ContactUs
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    props: true
  },
  {
    path: '*',
    redirect: { name: '404', params: { resource: 'page' } }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return { selector: to.hash }
    }

    return { x: 0, y: 0 }
  }
})

export default router
