<template>
  <section class="!w-full">
    <div class="m-4 w-full">
      <div class="w-1/2 surrenderRCGI inline-block" />
      <div class="w-1/2 inline-block align-top px-3">
        <h3 class="text-left" :id="homePage.properties['ID']">
          {{ homePage.headline }}
        </h3>
        <span>{{ homePage.body }}</span>
      </div>
    </div>
    <div class="m-4">
      <div
        v-for="article in this.homePage.related"
        :key="article.key"
        class="px-4 w-1/3 inline-block align-top"
      >
        <h3 :id="article.properties['ID']">
          {{ article.headline }}
        </h3>
        <span>{{ article.body }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  computed: {
    ...mapState(['rcg']), // RCGFlow#mapRCGtoView
    homePage() {
      // RCGFlow#filterRCGtoView
      return _.find(this.rcg, ['properties.NAVLINKNAME', 'Home'])
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/home';
</style>
