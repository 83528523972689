<template>
  <section>
    <h1 class="mx-auto mb-3" :id="resourcesPage.properties['ID']">
      {{ resourcesPage.headline }}
    </h1>
    <div class="w-full">{{ resourcesPage.body }}</div>
    <section
      v-for="resource in resourcesPage.related"
      :key="resource.key"
      class="my-3 w-full"
    >
      <h3 class="text-left" :id="resource.properties['ID']">
        {{ resource.headline }}
      </h3>
      <div class="w-full">{{ resource.body }}</div>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  computed: {
    ...mapState(['rcg']),
    resourcesPage() {
      return _.find(this.rcg, ['properties.NAVLINKNAME', 'Resources'])
    }
  }
}
</script>

<style scoped></style>
