import _ from 'lodash'

import rcgCalcQxMap from '../data/rcgCalcQxMap.js'
const rcgCalcQxMapObj = JSON.parse(atob(rcgCalcQxMap))

// RCGCalcFlow.md#r1
function ruleTraverser(ruleNode, answers) {
  let combinedProposals = {}
  const ifDirective = _.find(ruleNode.related, ['headline', 'IF'])
  let ifDirectiveSatisfied = Boolean(ifDirective)
  // RCGCalcFlow.md#r1-IF-section
  if (ifDirectiveSatisfied) {
    // handle rule set IF THEN
    while (ifDirectiveSatisfied === true) {
      if (ifDirective.related.length === 0) {
        break
      }
      for (const condition of ifDirective.related) {
        // RCGCalcFlow.md#r1-verify
        ifDirectiveSatisfied &= verifyCalcCondition(condition, answers)
      }
    }
    // RCGCalcFlow.md#r1-THEN-section
    if (ifDirectiveSatisfied) {
      console.log('conditionSetSatisfied:', ruleNode)
      const thenDirective = _.find(ruleNode.related, ['headline', 'THEN'])
      const isProposal = _.find(thenDirective.related, 'properties.PROPITEMID')
      // RCGCalcFlow.md#r1-THEN-proposal
      if (isProposal) {
        for (const proposal of thenDirective.related) {
          combinedProposals[proposal.properties['PROPITEMID']] = proposal
        }
      } else {
        // RCGCalcFlow.md#r1-THEN-sections
        combinedProposals = _.defaultsDeep(
          combinedProposals,
          ruleTraverser(thenDirective, answers)
        )
      }
    }
  } else {
    // RCGCalcFlow.md#r1-IF-section
    // recursively go through sections
    for (const section of ruleNode.related) {
      const proposals = ruleTraverser(section, answers)
      if (!_.isEmpty(proposals)) {
        combinedProposals = _.defaultsDeep(combinedProposals, proposals)
        // if this section is a ConditionSet ie has an IF child then stop reading other sections
        const isConditionSet = _.find(section.related, ['headline', 'IF'])
        if (isConditionSet) {
          break
        }
      }
    }
  }
  return combinedProposals
}

function printCondition(condition) {
  console.log('+++======')
  console.log(condition)
  console.log(condition.headline)
  console.log(condition.properties['AGGREGATE_ALL'])
  console.log(condition.properties['OPERATOR_ALL'])
  console.log(condition.properties['VALUE'])
  console.log(condition.properties)
  console.log('---======')
}

function round(x, digits) {
  return parseFloat(parseFloat(x).toFixed(digits))
}

const average = (list) =>
  round(
    list.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr)) /
      list.length,
    2
  )
const sum = (list) =>
  round(
    list.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr)),
    2
  )

// RCGCalcFlow.md#r1-verify
function verifyCalcCondition(condition, answers) {
  let values
  let slidersAnswers
  let conditionSelections
  let filteredAnswers

  const qtype =
    rcgCalcQxMapObj[condition.properties['QUESTIONID']].properties['QTYPE_ALL']

  switch (qtype) {
    case 'MultiOne':
    case 'MultiAll':
      values = answers[condition.properties['QUESTIONID']]
      return (
        Boolean(values) &&
        getComparator(condition)(values, condition.properties['SELECTIONID'])
      )

    case 'Slide':
      switch (condition.properties['AGGREGATE_ALL']) {
        case 'Only':
          slidersAnswers = answers[condition.properties['QUESTIONID']]
          conditionSelections = condition.properties['SELECTIONID'].split(' ')
          if (slidersAnswers) {
            filteredAnswers = _.pickBy(
              slidersAnswers,
              function (value, sliderAnswerId) {
                return conditionSelections.includes(sliderAnswerId)
              }
            )
            values = _.flatten(Object.values(filteredAnswers))
          }
          break
        case 'All':
        case undefined:
          slidersAnswers = answers[condition.properties['QUESTIONID']]
          if (slidersAnswers) {
            values = _.flatten(Object.values(slidersAnswers))
          }
          break
        default:
          printCondition(condition)
          console.warn(
            `UNLISTED AGGREGATE_ALL: ${condition.properties['AGGREGATE_ALL']}`
          )
      }
      return (
        Boolean(values) &&
        getComparator(condition)(values, condition.properties['VALUE'])
      )
    default:
      printCondition(condition)
      console.warn(`Undefined QTYPE_ALL ${condition.properties['QTYPE_ALL']}`)
  }

  return false
}

function getComparator(condition) {
  let comparator
  switch (condition.properties['OPERATOR_ALL']) {
    case 'Select':
      comparator = function (answer, expectedValues) {
        return expectedValues
          .split(' ')
          .every((reqAnswer) => Object.values(answer).includes(reqAnswer))
      }
      break
    case 'NotEqual':
      comparator = function (values, expectedValue) {
        return values.every(
          (value) => round(value, 2) !== round(expectedValue, 2)
        )
      }
      break
    case 'GreatEqual':
      comparator = function (values, expectedValue) {
        return values.every(
          (value) => round(value, 2) >= round(expectedValue, 2)
        )
      }
      break
    case 'LessEqual':
      comparator = function (values, expectedValue) {
        return values.every(
          (value) => round(value, 2) <= round(expectedValue, 2)
        )
      }
      break
    case 'EqualAver':
      comparator = function (values, expectedValue) {
        return average(values) === round(expectedValue, 2)
      }
      break
    case 'LessEqualAver':
      comparator = function (values, expectedValue) {
        return average(values) <= round(expectedValue, 2)
      }
      break
    case 'LessAver':
      comparator = function (values, expectedValue) {
        return average(values) < round(expectedValue, 2)
      }
      break
    case 'GreatEqualAver':
      comparator = function (values, expectedValue) {
        return average(values) >= round(expectedValue, 2)
      }
      break
    case 'GreatAver':
      comparator = function (values, expectedValue) {
        return average(values) > round(expectedValue, 2)
      }
      break
    case 'LessTotal':
      comparator = function (values, expectedValue) {
        return sum(values) < round(expectedValue, 2)
      }
      break
    default:
      console.error(
        `UNLISTED OPERATOR: ${condition.properties['OPERATOR_ALL']}`
      )
      comparator = function (value, expectedValue) {
        console.log(`default comparator ${value} with ${expectedValue}`)
        return false
      }
  }
  return comparator
}

export default ruleTraverser
