<template>
  <section>
    <section
      v-for="coverLetter in section.related"
      :key="coverLetter.properties['ID']"
    >
      <div
        v-if="coverLetter.body"
        v-html="renderTemplated(coverLetter.body)"
        class="text-sm"
      ></div>
    </section>
  </section>
</template>

<script>
import { renderTemplated } from '@/common/helpers'

export default {
  name: 'ProposalCover',
  props: {
    section: {
      type: Object,
      required: true
    },
    proposalVars: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    renderTemplated(textTemplate) {
      return renderTemplated(textTemplate, this.proposalVars)
    }
  }
}
</script>

<style scoped></style>
