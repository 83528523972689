<template>
  <section>
    <h1 class="mx-auto mb-3" :id="careersPage.properties['ID']">
      {{ careersPage.headline }}
    </h1>
    <div class="w-full">{{ careersPage.body }}</div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  computed: {
    ...mapState(['rcg']),
    careersPage() {
      return _.find(this.rcg, ['properties.NAVLINKNAME', 'Careers'])
    }
  }
}
</script>

<style scoped></style>
