const usGovHardTyranny = {
  answers: {
    '331f99d0-4fe7-48c7-9798-02bf672a16cb': ['ivan'],
    '6a3e1b7e-b754-4a9d-815b-557a06462e09': ['ivan@UsGov.com'],
    'e7d62eef-211a-4384-92da-e66e5d9927df': ['ivanUsGov'],
    '54b66313-bbb1-4b87-815b-2ff3e921e817': ['ivanUsGovTarget'],
    '2747c83c-946c-4a1b-b5f0-9b8201647bb7': [
      '81c12534-389b-4441-92fd-08332e7aecde'
    ],
    '3cbef2ad-875b-4a02-8080-f37d973b59xd': [
      'd978e4b3-b886-4b64-b979-0d32a4b069af'
    ],
    '402655cd-915f-49af-b44a-1ed03475436c': [
      '36aef883-af3a-4e30-bfad-8dfa751346e3'
    ],
    '1f17445b-fcef-46b7-a42d-cfb7692375eb': {
      '6585fb75-e8b1-4155-aafc-6fc949f6399b': [7],
      '2c785077-d302-4668-8a41-9a32ed8e91bd': [7],
      '4e835137-ddf9-46bf-ab9a-5eb4d6993270': [7],
      '667c8b3e-ea72-4474-9a3f-99fa3ab6b937': [7],
      '033e3794-3e0d-4c07-9ea5-fd8ee0be642c': [7],
      '35b15b67-fed0-4219-862e-9c70d6bae144': [7],
      '5a5c9376-7e17-4809-82b8-2519c8651ebb': [7],
      'd54e6c38-7b73-466b-8ffc-7973eb40cfee': [7]
    },
    'c95e37c1-c124-41ab-ac1b-c20fb0e83a14': {
      'f8fa1715-07aa-407b-970c-5d176c6eeeb3': [4],
      '820e78ac-7783-4ad4-a42b-ff245e73931e': [7],
      '4da04327-3514-4104-bcdf-70480c852801': [7],
      '4a1bceb0-2cbf-4e88-aea3-0b16cbb5b9d8': [7],
      '9c26ec55-1288-4263-aef0-2b92ae2b0f5a': [7],
      'f98b5b19-ef75-4101-adce-da43dc69dcda': [7],
      'f4d15693-6a7d-42aa-868b-e7305b86ae3f': [7],
      '16079cea-4270-4a4c-b250-788f3db88209': [7],
      'c9478073-55f2-4ff0-8283-8ddcd08240ff': [7],
      '9ea567bf-c04d-4f87-ba67-c6522f71c734': [7],
      '03eb9109-e657-41af-afe7-dc08199c1753': [7]
    },
    'c5ce5c91-6c30-4c10-a919-f62820579d20': [
      '6a9a1bbf-5d22-4e45-802e-15b8736ff35a'
    ],
    'bae97275-c370-4714-ad66-7d0ab6f128cf': [
      '2ea98d77-0796-4225-8c8b-d94809528998'
    ],
    '5bd950af-4940-4703-987f-30a96e6b961a': {
      'e8003685-123d-4cfb-9a71-3d76556fb1bc': [10],
      'ee83c37d-9f3b-46f0-9da8-540b275e9970': [9],
      'cc0c3031-ea7d-4786-8257-40605ed5acf5': [10],
      '7c255398-0351-490a-bdec-754a6db325a1': [9],
      '40a3f8ff-d9a4-47af-922e-12eed58cdca3': [9],
      '6b84c386-42f5-46f2-8427-828b01379e63': [9],
      '48acb59e-e409-4547-8377-8a2283c33919': [9],
      '90c5dbbe-54d5-476d-8ab1-97f611ea5152': [8],
      'be987891-7bfc-4f8b-88b3-ff62ab1e3126': [9],
      'b81ad7ea-8192-4d46-ac66-d684800a890e': [9],
      'b489901e-b7de-45a4-b265-bec2a20480e9': [9],
      '6a04b54e-6223-4c7f-8e7d-d8f31f3a93f5': [9],
      '4e1f93f0-e1af-4651-88f4-4c0590bf1d5e': [9],
      'f4c0047d-bf46-4f40-a657-cb3b05697122': [9],
      '06851978-e931-440a-8912-7689cf54ae8b': [10]
    },
    '8f3f3386-e69a-47dd-980f-1f2d1960db77': {
      '2185bbca-f80a-4f5b-a356-f15e88ac300a': [9],
      '4c2e71e4-9660-45df-8e1a-7a6bced622bb': [10]
    },
    'd666697a-8d0e-4a91-b391-067d6362e8db': [
      '3005c70f-e547-4965-ba5f-f545b0b1415f'
    ],
    'b700669c-76b6-4dff-8297-13af42fdf045': [
      'e88aaa80-c79d-4047-94fd-735de3d0a35f',
      '801ae9ed-1bb8-4476-9d4e-7132426565fe'
    ],
    '03a8eaf9-c6a4-4be4-b2c7-a94f7b76c942': {
      'cef15521-dd5d-4248-b4ba-c77dd32d29b8': [8],
      'ec13b096-dd4c-4c22-a6bd-2173a790b95d': [7],
      '97b9ff80-8f70-475b-832b-e5d6030c3364': [8]
    },
    'c11942c8-0204-4f76-af41-e568e0e3cb7a': [
      '6b821042-900d-4aea-b642-1e93af3823b3'
    ],
    '3013c123-3fe3-49c4-9f33-86e6b66cfd9f': [
      '6b821042-900d-4aea-b642-1e93af3823b3',
      'b38f470a-b7c8-42dd-88ab-12d707fe80fe',
      '136769b1-fa6e-425c-9eee-177a3fb5a63a'
    ]
  },
  proposalVars: {
    'user-name': 'ivan',
    'user-email': 'ivan@UsGov.com',
    'user-org-name': 'ivanUsGov',
    'user-target-regime': 'ivanUsGovTarget'
  },
  branches: {
    Org: 'USGov',
    RC: 'Hard',
    Power: 'Tyranny'
  },
  branchedIdx: 14,
  branched: [
    {
      key: 'orgNode_2434.**',
      level: 2,
      headline: 'Your Information',
      body: '',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        ID: '610dfe31-7cc2-402e-bec6-3916134a7526',
        QTYPE_ALL: 'MultiOne MultiAll Slide Text Email'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2435.***',
          level: 3,
          headline: 'Name',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            QTYPE_ALL: 'Text',
            CHARLIMIT_ALL: '50',
            PROPOSALVAR_ALL: 'user-name',
            ID: '331f99d0-4fe7-48c7-9798-02bf672a16cb'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2436.***',
          level: 3,
          headline: 'email',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            QTYPE_ALL: 'Email',
            PROPOSALVAR_ALL: 'user-email',
            CHARLIMIT_ALL: '50',
            ID: '6a3e1b7e-b754-4a9d-815b-557a06462e09'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2437.***',
          level: 3,
          headline: 'Name of Your Organization',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            CHARLIMIT_ALL: '50',
            QTYPE_ALL: 'Text',
            PROPOSALVAR_ALL: 'user-org-name',
            ID: 'e7d62eef-211a-4384-92da-e66e5d9927df'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2438.***',
          level: 3,
          headline: 'Name of your target regime',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            PROPOSALVAR_ALL: 'user-target-regime',
            CHARLIMIT_ALL: '50',
            QTYPE_ALL: 'Text',
            ID: '54b66313-bbb1-4b87-815b-2ff3e921e817'
          },
          drawer: {},
          related: []
        }
      ]
    },
    {
      key: 'orgNode_2439.**',
      level: 2,
      headline: 'Your Organization and Goals',
      body: '',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        ID: '3cbef2ad-875b-4a02-8080-f37d973b59bd',
        QTYPE_ALL: 'MultiOne MultiAll Slide Text Email'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2440.***',
          level: 3,
          headline: 'Your Organization',
          body: "Please select the type of organization's type that wishes to change a regime by checking one.\n",
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '3cbef2ad-875b-4a02-8080-f37d973b59xd',
            QTYPE_ALL: 'MultiOne',
            BRANCHQTYPE_ALL: 'Org'
          },
          drawer: {},
          related: [
            {
              key: 'orgNode_2441.****',
              level: 4,
              headline: 'US Government',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: 'd978e4b3-b886-4b64-b979-0d32a4b069af',
                ANSWER_ALL: 'USGov',
                QTYPE_ALL: 'MultiOne'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2442.****',
              level: 4,
              headline: 'Ex-US Government',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '4db69f97-7b8f-4741-925b-dddb8e152605',
                ANSWER_ALL: 'xUSGov',
                QTYPE_ALL: 'MultiOne'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2443.****',
              level: 4,
              headline: 'Non-Governmental Organization',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: 'b2160f14-336a-4ea1-bc7b-68ab88c913b0',
                ANSWER_ALL: 'NGO',
                QTYPE_ALL: 'MultiOne'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2444.****',
              level: 4,
              headline: 'Company',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '3b16a746-3212-4abb-8910-81628e24ae7c',
                ANSWER_ALL: 'Company',
                QTYPE_ALL: 'MultiOne'
              },
              drawer: {},
              related: []
            }
          ]
        },
        {
          key: 'orgNode_2445.***',
          level: 3,
          headline: 'Your Goals',
          body: 'Please select the answer that best describes your strategic goals for regime change.\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '2747c83c-946c-4a1b-b5f0-9b8201647bb7',
            QTYPE_ALL: 'MultiOne',
            BRANCHQTYPE_ALL: 'RC'
          },
          drawer: {},
          related: [
            {
              key: 'orgNode_2446.****',
              level: 4,
              headline: 'Hard Regime Change',
              body: 'The regime must go. Consequences will be delt with along the way.\n',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ANSWER_ALL: 'Hard',
                ID: '81c12534-389b-4441-92fd-08332e7aecde',
                QTYPE_ALL: 'MultiOne'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2447.****',
              level: 4,
              headline: 'Neutral Regime Change',
              body: 'Regime change must happen but within certain parameters.\n',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '8e713277-efe0-4c0e-9336-18f6cf046483',
                ANSWER_ALL: 'Neutral',
                QTYPE_ALL: 'MultiOne'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2448.****',
              level: 4,
              headline: 'Soft Regime Change',
              body: 'Change the regime, maintaining the moral high ground and build a nation.\n',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: 'a1987b9f-35a4-4d1f-9297-74b7eda8451c',
                ANSWER_ALL: 'Soft',
                QTYPE_ALL: 'MultiOne'
              },
              drawer: {},
              related: []
            }
          ]
        }
      ]
    },
    {
      key: 'orgNode_2449.**',
      level: 2,
      headline: 'More About You and Your Goals',
      body: '',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        ID: '9fa46a31-b4b2-47f2-b574-26365b3cddf0',
        QTYPE_ALL: 'MultiOne MultiAll Slide Text Email'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2450.***',
          level: 3,
          headline: 'Your US Goverment Agency',
          body: ' Please specify what type(s) of US Government Department or Agency you are by checking all that apply.\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '402655cd-915f-49af-b44a-1ed03475436c',
            QTYPE_ALL: 'MultiAll',
            BRANCH_ALL: 'USGov'
          },
          drawer: {},
          related: [
            {
              key: 'orgNode_2451.****',
              level: 4,
              headline: 'military',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: 'cfc56e70-fa41-4ce7-b121-9bd447fe9e55',
                QTYPE_ALL: 'MultiAll'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2452.****',
              level: 4,
              headline: 'intelligence',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: 'd275bb93-bdc6-472e-a42d-ddada7ea8fab',
                QTYPE_ALL: 'MultiAll'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2453.****',
              level: 4,
              headline: 'rogue',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '36aef883-af3a-4e30-bfad-8dfa751346e3',
                QTYPE_ALL: 'MultiAll'
              },
              drawer: {},
              related: []
            }
          ]
        },
        {
          key: 'orgNode_2464.***',
          level: 3,
          headline: 'Weight Your Hard Regime Change Preferences (8q 9 = 720)',
          body: 'As you seek a Hard Regime Change, please tell us more about the Hard Regime Change you seek checking a number 1 through 10 for the following questions, where 1 means "not at all important," and 10 means "of absolute importance.\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            QTYPE_ALL: 'Slide',
            ID: '1f17445b-fcef-46b7-a42d-cfb7692375eb',
            BRANCH_ALL: 'Hard'
          },
          drawer: {},
          related: [
            {
              key: 'orgNode_2465.****',
              level: 4,
              headline:
                'Annex captured territory as a permanent part or protectorate of your country or wholly owned subsidiary of your company',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '6585fb75-e8b1-4155-aafc-6fc949f6399b',
                MULTIPLIER_ALL: '2',
                QTYPE_ALL: 'Slide'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2466.****',
              level: 4,
              headline: "Confiscate Target's financial assets",
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '2c785077-d302-4668-8a41-9a32ed8e91bd',
                MULTIPLIER_ALL: '1.5',
                QTYPE_ALL: 'Slide'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2467.****',
              level: 4,
              headline:
                "Confiscate or other wise exploit Target's natural resources",
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '4e835137-ddf9-46bf-ab9a-5eb4d6993270',
                MULTIPLIER_ALL: '2',
                QTYPE_ALL: 'Slide'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2468.****',
              level: 4,
              headline:
                'Occupy country indefinitely, cultivating the ambiguity of your objectives post-Regime Change',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '667c8b3e-ea72-4474-9a3f-99fa3ab6b937',
                MULTIPLIER_ALL: '1.5',
                QTYPE_ALL: 'Slide'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2469.****',
              level: 4,
              headline:
                'Provide internal security and law enforcement yourself',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '033e3794-3e0d-4c07-9ea5-fd8ee0be642c',
                QTYPE_ALL: 'Slide'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2470.****',
              level: 4,
              headline:
                'Proceed to roll-up similar regimes in the region into regionally dominate player',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '35b15b67-fed0-4219-862e-9c70d6bae144',
                MULTIPLIER_ALL: '2',
                QTYPE_ALL: 'Slide'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2471.****',
              level: 4,
              headline: 'Decommission entire standing armed forces',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '5a5c9376-7e17-4809-82b8-2519c8651ebb',
                QTYPE_ALL: 'Slide'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2472.****',
              level: 4,
              headline:
                'Remove members of parties supporting Target Regime from all levels of government and public service of any kind',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: 'd54e6c38-7b73-466b-8ffc-7973eb40cfee',
                QTYPE_ALL: 'Slide'
              },
              drawer: {},
              related: []
            }
          ]
        }
      ]
    },
    {
      key: 'orgNode_2496.**',
      level: 2,
      headline:
        'Assessing Your Regime Change Market Conditions (11q -1 = -110)',
      body: 'Please answer the following questions about your target regime\'s regional stability by checking a number 1 through 10 where 1 means, "This does not describe our regime change market conditions." and 10 means, "This precisely describes our regime change market conditions."\n',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        ID: 'c95e37c1-c124-41ab-ac1b-c20fb0e83a14',
        QTYPE_ALL: 'Slide'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2497.***',
          level: 3,
          headline:
            'No other regime change player is in the market to change our Target Regime.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '820e78ac-7783-4ad4-a42b-ff245e73931e',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2498.***',
          level: 3,
          headline:
            'A military coup is either in progress or likely to occur within the next 12 months.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'f8fa1715-07aa-407b-970c-5d176c6eeeb3',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2499.***',
          level: 3,
          headline:
            'Religious sectarian insurrection or civil war has started or is likely to start within the next 12 months.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '4da04327-3514-4104-bcdf-70480c852801',
            MULTIPLIER_ALL: '-1',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2500.***',
          level: 3,
          headline:
            'Ethnic or tribal insurrection or civil war is in progress or likely to occur within the next 12 months.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '4a1bceb0-2cbf-4e88-aea3-0b16cbb5b9d8',
            MULTIPLIER_ALL: '-1',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2501.***',
          level: 3,
          headline:
            'Regional alliances and conflict are based on shared ideology.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '9c26ec55-1288-4263-aef0-2b92ae2b0f5a',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2502.***',
          level: 3,
          headline:
            'Regional alliances and conflict are based on religious or sectarian differences.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'f98b5b19-ef75-4101-adce-da43dc69dcda',
            MULTIPLIER_ALL: '-1',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2503.***',
          level: 3,
          headline:
            'Regional alliances and conflicts are mostly practical in nature, as in "the enemies of my enemies are my friends," or "What\'s mine is mine and what\'s yours is mine, too."',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'f4d15693-6a7d-42aa-868b-e7305b86ae3f',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2504.***',
          level: 3,
          headline: 'Regime has significant alliances with Russia or China',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '16079cea-4270-4a4c-b250-788f3db88209',
            MULTIPLIER_ALL: '-1',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2505.***',
          level: 3,
          headline:
            'Your firm/agency has 50% or more of regional regime change market share',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'c9478073-55f2-4ff0-8283-8ddcd08240ff',
            MULTIPLIER_ALL: '1.5',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2506.***',
          level: 3,
          headline: 'Regime change in your target region has been comodified',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '9ea567bf-c04d-4f87-ba67-c6522f71c734',
            MULTIPLIER_ALL: '1.5',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2507.***',
          level: 3,
          headline:
            'The number of regimes being changed annually has increased, on average, over the last 5 years',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '03eb9109-e657-41af-afe7-dc08199c1753',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        }
      ]
    },
    {
      key: 'orgNode_2508.**',
      level: 2,
      headline: 'Your Target Regime Source of Authority',
      body: 'Please check the statement that best describes the source of the authority of your Target Regime.\n',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        ID: 'c5ce5c91-6c30-4c10-a919-f62820579d20',
        QTYPE_ALL: 'MultiOne',
        BRANCHQTYPE_ALL: 'Power'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2509.***',
          level: 3,
          headline: 'Democracy',
          body: 'Freely and fairly elected democratic government with normally orderly transitions of government.\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '6da89499-80f0-43a2-9fa6-98e77ca2c063',
            ANSWER_ALL: 'Democracy',
            QTYPE_ALL: 'MultiOne'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2510.***',
          level: 3,
          headline: 'Tyranny',
          body: "Citizens' autonomy is determined at the sole discretion of an unelected authority. If elections are held, they are not free or fair.\n",
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ANSWER_ALL: 'Tyranny',
            ID: '6a9a1bbf-5d22-4e45-802e-15b8736ff35a',
            QTYPE_ALL: 'MultiOne'
          },
          drawer: {},
          related: []
        }
      ]
    },
    {
      key: 'orgNode_2511.**',
      level: 2,
      headline: 'Nature of tyrannical regime',
      body: 'Please check the statement that most closely describes the tyrannical nature of your Target Regime.\n',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        QTYPE_ALL: 'MultiOne',
        BRANCH_ALL: 'Tyranny',
        ID: 'bae97275-c370-4714-ad66-7d0ab6f128cf'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2512.***',
          level: 3,
          headline: 'Secular "Strongman"',
          body: 'A single person with absolute or near absolute power and no need for institutional legitimacy\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '2ea98d77-0796-4225-8c8b-d94809528998',
            QTYPE_ALL: 'MultiOne'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2513.***',
          level: 3,
          headline: 'Theocracy',
          body: 'The military and secular administration report to a religious leader or leaders.\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '6d534ed6-faed-49fa-9bc0-6d370bb535d0',
            QTYPE_ALL: 'MultiOne'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2514.***',
          level: 3,
          headline: 'Oligarchy',
          body: 'A group industrialists, media owners, or other hyper-wealthy, usually fronted by a powerless facade of legitimate government.\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '296d75cb-143f-407c-be36-ddeaf5658c7b',
            QTYPE_ALL: 'MultiOne'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2515.***',
          level: 3,
          headline: 'Absolute Monarchy',
          body: 'Inherited power system with clear lines of succession, little palace intrigue, and few power diluting dependencies.\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '710366e3-3f5b-438d-ba44-464f884fdbcf',
            QTYPE_ALL: 'MultiOne'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2516.***',
          level: 3,
          headline: 'Weak Monarchy',
          body: 'Inherited system of power with weakness(es) such as dependencies on the First and/or Third Estate(s) and/or unclear lines of succession.\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'bcabad28-42fc-4ea8-aa08-e6e0ee37e9c7',
            QTYPE_ALL: 'MultiOne'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2517.***',
          level: 3,
          headline: 'Unstable Alliance',
          body: 'Loose, shifting alliance of power bases such as tribes, oligarchs, or religious sects.\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '171f9803-f3c2-4151-af31-c9b2f66f1df9',
            QTYPE_ALL: 'MultiOne'
          },
          drawer: {},
          related: []
        }
      ]
    },
    {
      key: 'orgNode_2518.**',
      level: 2,
      headline: 'Loyalty to Your Target Regime',
      body: 'Please answer the following questions by checking a number 1 through 10 where 1 means, "This does not describe our Target Regime at all," and 10 means, "This precisely the nature of our Target Regime." 5 means, "This is true about our Target Regime, but not more so than the average for regimes that are commonly targetted for change."\n',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        ID: '5bd950af-4940-4703-987f-30a96e6b961a',
        QTYPE_ALL: 'Slide'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2519.***',
          level: 3,
          headline:
            "Senior members of Target Regime's government and armed forces are broadly loyal to the (de facto) government.",
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'e8003685-123d-4cfb-9a71-3d76556fb1bc',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2520.***',
          level: 3,
          headline:
            'Popular leaders outside government or the armed forces are fomenting significant disloyalty to the regime among members of the general population.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'ee83c37d-9f3b-46f0-9da8-540b275e9970',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2521.***',
          level: 3,
          headline:
            'Outside interests are fomenting disloyalty to the regime among members of the general population.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'cc0c3031-ea7d-4786-8257-40605ed5acf5',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2522.***',
          level: 3,
          headline:
            'The general public perceives stability and strength in the parts economy that effects their day to day lives.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '7c255398-0351-490a-bdec-754a6db325a1',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2523.***',
          level: 3,
          headline:
            'There is enough sharing of wealth and power with elite (military, religious, commerical, etc.) to keep them more or less happy.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '40a3f8ff-d9a4-47af-922e-12eed58cdca3',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2524.***',
          level: 3,
          headline:
            'Religious attributes or authority are vested in the government by dominant sect(s).',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '6b84c386-42f5-46f2-8427-828b01379e63',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2525.***',
          level: 3,
          headline:
            'Surveillance and fear of punishment by the regime central authority grip members of the elite.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '48acb59e-e409-4547-8377-8a2283c33919',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2526.***',
          level: 3,
          headline:
            'Surveillance and fear of punishment by the regime central authority grip the general public.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '90c5dbbe-54d5-476d-8ab1-97f611ea5152',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2527.***',
          level: 3,
          headline:
            'The general population perceives the regime to be weak or underthreat.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'be987891-7bfc-4f8b-88b3-ff62ab1e3126',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2528.***',
          level: 3,
          headline:
            'Members of the elite are aware of disloyalty among groups of senior leaders.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'b81ad7ea-8192-4d46-ac66-d684800a890e',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2529.***',
          level: 3,
          headline:
            'There is suspicion/knowledge of a planned coup and belief it could succeed.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'b489901e-b7de-45a4-b265-bec2a20480e9',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2530.***',
          level: 3,
          headline:
            'There are significant ideological differences with the government or between organized factions of the general population.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '6a04b54e-6223-4c7f-8e7d-d8f31f3a93f5',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2531.***',
          level: 3,
          headline:
            'There are heated religious sectarian differences within the government and/or general population',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '4e1f93f0-e1af-4651-88f4-4c0590bf1d5e',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2532.***',
          level: 3,
          headline:
            'There is widespread anger over repression of religious freedoms.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'f4c0047d-bf46-4f40-a657-cb3b05697122',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2533.***',
          level: 3,
          headline:
            'Senior leaders have competing followings among groups of civilians outside the government.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '06851978-e931-440a-8912-7689cf54ae8b',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        }
      ]
    },
    {
      key: 'orgNode_2534.**',
      level: 2,
      headline: "Assessing Your Target Regime's Armed Forces",
      body: 'Please answer the following questions by checking a number 1 through 10 where 1 means, "This is not at all true about our Target Regime\'s armed forces," and 10 means, "This accurately describes our Target Regime\'s armed forces."\n',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        ID: '8f3f3386-e69a-47dd-980f-1f2d1960db77',
        QTYPE_ALL: 'Slide'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2535.***',
          level: 3,
          headline:
            'The standing, regular armed forces of the Target Regime are mostly disciplined and well-trained.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '2185bbca-f80a-4f5b-a356-f15e88ac300a',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2536.***',
          level: 3,
          headline:
            'There are special forces with direct reporting to top leaders of the goverment.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '4c2e71e4-9660-45df-8e1a-7a6bced622bb',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        }
      ]
    },
    {
      key: 'orgNode_2537.**',
      level: 2,
      headline: 'Force levels',
      body: 'Please check one for each branch of the military.\n',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        ID: '12fa7a96-69bc-4d61-a771-c500c62a948c',
        QTYPE_ALL: 'MultiOne MultiAll Slide Text Email'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2538.***',
          level: 3,
          headline: 'Regular army:',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'd666697a-8d0e-4a91-b391-067d6362e8db',
            QTYPE_ALL: 'MultiOne'
          },
          drawer: {},
          related: [
            {
              key: 'orgNode_2539.****',
              level: 4,
              headline: '10,000 or less soldiers',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '26426c3e-76a0-4a54-ace1-8171aa66dcef',
                QTYPE_ALL: 'MultiOne'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2540.****',
              level: 4,
              headline: '10,001 to 50,000 soldiers',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: 'c67d5c5a-b2f3-4b05-843f-838b669513ee',
                QTYPE_ALL: 'MultiOne'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2541.****',
              level: 4,
              headline: '50,001 200,000 soldiers',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '5b04a228-4256-4d06-9a87-2e86c030970f',
                QTYPE_ALL: 'MultiOne'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2542.****',
              level: 4,
              headline: '200,001 to 500,000 soldiers',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '3005c70f-e547-4965-ba5f-f545b0b1415f',
                QTYPE_ALL: 'MultiOne'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2543.****',
              level: 4,
              headline: '500,001 to 1,000,000 soldiers',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '305f00ef-037e-4a4c-99fe-cc28b7093592',
                QTYPE_ALL: 'MultiOne'
              },
              drawer: {},
              related: []
            },
            {
              key: 'orgNode_2544.****',
              level: 4,
              headline: 'More than a million soldiers',
              body: '',
              tag: null,
              tags: {},
              todo: null,
              priority: null,
              scheduled: null,
              deadline: null,
              properties: {
                ID: '5f9595e5-0e15-49f0-bd1a-32503ebd1ff9',
                QTYPE_ALL: 'MultiOne'
              },
              drawer: {},
              related: []
            }
          ]
        },
        {
          key: 'orgNode_2545.***',
          level: 3,
          headline: 'Special/elite forces outside chain of command',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '6b1f7c33-cff0-49c4-a3c8-6108c8174f69'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2546.***',
          level: 3,
          headline: 'Air Force',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'b2c69896-333b-434a-bf23-49f80be27a52'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2547.***',
          level: 3,
          headline: 'Navy',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '9b6eb55b-ec6b-456a-9dee-fd72b334be9b'
          },
          drawer: {},
          related: []
        }
      ]
    },
    {
      key: 'orgNode_2548.**',
      level: 2,
      headline: 'Current deployment status of Target Regime armed forces',
      body: 'Please check all that apply.\n',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        ID: 'b700669c-76b6-4dff-8297-13af42fdf045',
        QTYPE_ALL: 'MultiAll'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2549.***',
          level: 3,
          headline: 'Multi-front civil war',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'e88aaa80-c79d-4047-94fd-735de3d0a35f',
            QTYPE_ALL: 'MultiAll'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2550.***',
          level: 3,
          headline: 'Defending attack by external enemy.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '545f3b52-7c30-444a-8a40-c9c27a7da523',
            QTYPE_ALL: 'MultiAll'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2551.***',
          level: 3,
          headline: 'Initiating attack on external enemy',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '801ae9ed-1bb8-4476-9d4e-7132426565fe',
            QTYPE_ALL: 'MultiAll'
          },
          drawer: {},
          related: []
        }
      ]
    },
    {
      key: 'orgNode_2552.**',
      level: 2,
      headline: 'Weapons of Mass Destruction',
      body: '',
      tag: null,
      tags: {},
      todo: 'TODO',
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        ID: 'd5819b49-f8d6-45bc-b0e9-7bf5fdbfb9e8',
        QTYPE_ALL: 'MultiAll'
      },
      drawer: {},
      related: []
    },
    {
      key: 'orgNode_2553.**',
      level: 2,
      headline: 'Health of Target Regime Economy',
      body: 'Please select one answer for each of the following economic metrics.\n',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        ID: '9154984f-77c3-4a47-878f-75e2689e2732',
        QTYPE_ALL: 'MultiOne MultiAll Slide Text Email'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2554.***',
          level: 3,
          headline: 'The per capita GDP',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '9b2e77db-716f-4aea-bea1-401b013bb0ee',
            QTYPE_ALL: 'MultiOne'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2555.***',
          level: 3,
          headline: 'The GINI coefficient',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '4d0fd2ca-b765-46fb-8f6c-83f13c8417ca',
            QTYPE_ALL: 'MultiOne'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2556.***',
          level: 3,
          headline: 'The inflation rate',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '1875250c-f019-44e7-83b1-1ae0bfed718d',
            QTYPE_ALL: 'MultiOne'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2557.***',
          level: 3,
          headline: 'The unemployment rate',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'ef373ceb-3c48-43c0-aca7-970d8fb884bd',
            QTYPE_ALL: 'MultiOne'
          },
          drawer: {},
          related: []
        }
      ]
    },
    {
      key: 'orgNode_2558.**',
      level: 2,
      headline: 'Your Current Intelligence Assets',
      body: 'Please answer the following questions by checking a number 1 through 10 where 1 means, "This is not at all true about our intelligence assets," and 10 means, "This accurately describes our intelligence assets assets are."\n',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        ID: '03a8eaf9-c6a4-4be4-b2c7-a94f7b76c942',
        QTYPE_ALL: 'Slide'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2559.***',
          level: 3,
          headline:
            "You have reliable informers at senior levels of the Target Regime's government and armed forces.",
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'cef15521-dd5d-4248-b4ba-c77dd32d29b8',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2560.***',
          level: 3,
          headline:
            'Your informers provide actionable, accurate, granular and complete of information',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'ec13b096-dd4c-4c22-a6bd-2173a790b95d',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2561.***',
          level: 3,
          headline: 'You want to extract your informants safely.',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '97b9ff80-8f70-475b-832b-e5d6030c3364',
            QTYPE_ALL: 'Slide'
          },
          drawer: {},
          related: []
        }
      ]
    },
    {
      key: 'orgNode_2562.**',
      level: 2,
      headline: 'Your Current Available Force Levels',
      body: 'Please check all types of available (may be deployed within the next 90 days) that apply.\n',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        QTYPE_ALL: 'MultiAll',
        ID: 'c11942c8-0204-4f76-af41-e568e0e3cb7a'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2563.***',
          level: 3,
          headline: 'Brigade or more of private fighters',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '6b821042-900d-4aea-b642-1e93af3823b3',
            QTYPE_ALL: 'MultiAll'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2564.***',
          level: 3,
          headline: 'None of the above',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'f6dbbe99-ef27-4652-aa4c-e64bb93b3f90',
            QTYPE_ALL: 'MultiAll'
          },
          drawer: {},
          related: []
        }
      ]
    },
    {
      key: 'orgNode_2565.**',
      level: 2,
      headline: 'Secrecy and Publicity',
      body: 'Please check all that apply to you secrcy and publicity requirements. (Please note that we require covert planning all our contractual agreements.)\n',
      tag: null,
      tags: {},
      todo: null,
      priority: null,
      scheduled: null,
      deadline: null,
      properties: {
        QTYPE_ALL: 'MultiAll',
        ID: '3013c123-3fe3-49c4-9f33-86e6b66cfd9f'
      },
      drawer: {},
      related: [
        {
          key: 'orgNode_2566.***',
          level: 3,
          headline: 'covert military preparation',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '9e3dc193-92b7-4f5a-9284-9bccfa986b1b',
            QTYPE_ALL: 'MultiAll'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2567.***',
          level: 3,
          headline: 'saber-rattling',
          body: 'Branded and promoted of in-theater preparation\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '69293225-2f7e-407c-8005-8891fc27758f',
            QTYPE_ALL: 'MultiAll'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2568.***',
          level: 3,
          headline: 'false-flag',
          body: 'Staged in-theater actions designed to provoke or justify preemptive attack\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'ece88166-7aad-4fb8-ac23-76936c19bfe5',
            QTYPE_ALL: 'MultiAll'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2569.***',
          level: 3,
          headline: 'covert operations',
          body: '',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '377694f0-9182-4afd-9135-a6ab02b535bb',
            QTYPE_ALL: 'MultiAll'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2570.***',
          level: 3,
          headline: '"shock and awe"',
          body: 'branded and promoted operations, especially just prior to a preemptive attack\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: 'b38f470a-b7c8-42dd-88ab-12d707fe80fe',
            QTYPE_ALL: 'MultiAll'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2571.***',
          level: 3,
          headline: 'embedded press',
          body: 'recruited, placed and managed carefully among operational units\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '553f809b-a00b-4766-87a6-a9685d969594',
            QTYPE_ALL: 'MultiAll'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2572.***',
          level: 3,
          headline: 'non-disclosure agreements',
          body: 'To be required of subcontractors. Please note while RCG always makes best efforts to protect client information, we do not enter into non-disclosure agreements and do not warrant the adherance to such agreements by subcontractors.\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '136769b1-fa6e-425c-9eee-177a3fb5a63a',
            QTYPE_ALL: 'MultiAll'
          },
          drawer: {},
          related: []
        },
        {
          key: 'orgNode_2573.***',
          level: 3,
          headline: 'counter-intelligence',
          body: '\n',
          tag: null,
          tags: {},
          todo: null,
          priority: null,
          scheduled: null,
          deadline: null,
          properties: {
            ID: '5ef5c09a-5341-46cd-a2f9-e3867c53d8f8',
            QTYPE_ALL: 'MultiAll'
          },
          drawer: {},
          related: []
        }
      ]
    }
  ]
}

export { usGovHardTyranny }
