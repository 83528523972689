<template>
  <div>
    <h5 class="block text-left font-semibold">{{ question.headline }}</h5>
    <span v-if="question.body" class="block font-medium italic">{{
      question.body
    }}</span>
    <template v-if="question.properties['QTYPE_ALL'] === 'MultiOne'">
      <span v-for="option in question.related" :key="option.key" class="my-0.5">
        <span class="flex flex-row h-6">
          <input
            type="radio"
            :value="option.properties['ID']"
            :id="option.key"
            class="h-full"
            v-model="questionAnswer"
            @change="notifyAnswer"
          />
          <label :for="option.key" class="ml-2 my-auto h-full truncate">{{
            option.headline
          }}</label>
        </span>
        <span v-if="option.body" class="ml-5 text-sm">{{ option.body }}</span>
      </span>
    </template>
    <template v-if="question.properties['QTYPE_ALL'] === 'MultiAll'">
      <span
        v-for="option in question.related"
        :key="option.key"
        class="flex flex-row h-6 my-0.5"
      >
        <input
          type="checkbox"
          :value="option.properties['ID']"
          :id="option.key"
          class="h-full"
          v-model="questionMultiAnswer"
          @change="notifyMultiAnswer"
        />
        <label :for="option.key" class="ml-2 my-auto h-full truncate">{{
          option.headline
        }}</label>
        <span v-if="option.body" class="ml-5 text-sm">{{ option.body }}</span>
      </span>
    </template>
    <template v-else-if="question.properties['QTYPE_ALL'] === 'Slide'">
      <div class="block h-8 mt-2 flex flex-row">
        <span class="my-auto font-semibold">1</span>
        <span class="flex flex-row w-7/12 justify-between">
          <input
            v-for="index in sliderCount"
            :key="index"
            type="radio"
            :value="index"
            :id="question.properties['ID'] + '_' + index"
            class="h-full mx-3"
            v-model="questionAnswer"
            @change="notifyAnswer"
          />
        </span>
        <span class="my-auto font-semibold">10</span>
      </div>
    </template>
    <template v-if="question.properties['QTYPE_ALL'] === 'Text'">
      <input
        v-model="questionAnswer"
        @change="notifyAnswer"
        placeholder="type here"
        class="border p-1"
      />
    </template>
    <template v-if="question.properties['QTYPE_ALL'] === 'Email'">
      <input
        type="email"
        v-model="questionAnswer"
        @change="notifyAnswer"
        placeholder="type your email here"
        class="border p-1"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'CalcQuestion',
  props: {
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      questionAnswer: '',
      questionMultiAnswer: [],
      sliderCount: 10
    }
  },
  methods: {
    notifyAnswer() {
      this.$emit('answer:changed', {
        question: this.question,
        answer: [this.questionAnswer]
      })
    },
    notifyMultiAnswer() {
      this.$emit('answer:changed', {
        question: this.question,
        answer: this.questionMultiAnswer
      })
    },
    randomiseAnswer: function () {
      const generateMultiAnswer = () => {
        for (let multiAnswer of this.question.related) {
          if (Math.random() < 0.5) {
            this.questionMultiAnswer.push(multiAnswer.properties['ID'])
          }
        }
      }
      function randomIntFromInterval(min, max) {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min)
      }

      switch (this.question.properties['QTYPE_ALL']) {
        case 'Text':
          this.questionAnswer = Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, '')
            .substr(0, 5)
          this.notifyAnswer()
          break
        case 'Email':
          this.questionAnswer =
            Math.random()
              .toString(36)
              .replace(/[^a-z]+/g, '')
              .substr(0, 5) + '@onehundred20user.com'
          this.notifyAnswer()
          break
        case 'MultiOne':
          if (this.question.related.length > 0) {
            this.questionAnswer =
              this.question.related[
                Math.floor(Math.random() * this.question.related.length)
              ].properties['ID']
            this.notifyAnswer()
          }
          break
        case 'MultiAll':
          this.questionMultiAnswer = []
          if (this.question.related.length > 0) {
            while (this.questionMultiAnswer.length === 0) {
              generateMultiAnswer()
            }
            this.notifyMultiAnswer()
          }
          break
        case 'Slide':
          this.questionAnswer = randomIntFromInterval(1, this.sliderCount)
          this.notifyAnswer()
          break
        default:
          console.warn('missing QTYPE_ALL')
      }
    }
  }
}
</script>

<style scoped></style>
