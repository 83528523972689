<template>
  <main class="flex flex-col justify-center mx-auto" id="app">
    <rcg-top />
    <router-view
      class="flex flex-wrap px-4 w-3/4 mx-auto"
      :key="$route.fullPath"
    />
  </main>
</template>

<script>
import RcgTop from './components/RcgTop.vue'

export default {
  name: 'App',
  components: {
    RcgTop
  }
}
</script>

<style></style>
